const INFO = {
	main: {
		title: "tconto.tech",
		name: "Torben Conto",
		email: "torbenmconto@gmail.com",
		logo: "../LOGO(4).png",
	},

	socials: {
		twitter: "https://twitter.com/tconto_tech",
		github: "https://github.com/torbenconto",
		linkedin: "https://linkedin.com/",
		instagram: "https://instagram.com/",
		stackoverflow: "https://stackoverflow.com/users/23245841/torben-conto",
		facebook: "https://facebook.com/",
	},

	homepage: {
		title: "The Art of Developology",
		description:
			"👋Hey there! I'm Torben, a 14-year-old who's interested in coding and creating cool stuff. Welcome to my portfolio, where I'll be sharing some of the projects I've been working on. Join me on this journey of exploration and learning as I dive into the world of software development. Let's have some fun with code! 🚀👩‍💻",
	},

	about: {
		title: "I’m Torben Conto and I’m a 14-year-old systems developer.",
		description:
			"Hi, I'm Torben. I'm a 14-year-old who's interested in coding, computers, and finance. I spend my time learning new things and creating projects with the knowledge I gain. Currently, I'm proficient in Go, Python, JavaScript, and TypeScript. I'm also familiar with C and learning Rust on the side.",
	},

	projects: [
		{
			title: "Plutus",
			description:
				"A stock data library using web scraping, hand crafted in golang.",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/go/go.png",
			linkText: "View Project",
			link: "https://github.com/torbenconto/plutus",
		},
		{
			title: "Plutus REST API",
			description:
				"A REST API for the Plutus stock data library, written in Go.",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/go/go.png",
			linkText: "View Project",
			link: "https://github.com/torbenconto/plutus-api",
		},

		{
			title: "Ares",
			description:
				"A simple, fast, and lightweight text editor written in C.",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/c/c.png",
			linkText: "View Project",
			link: "https://github.com/torbenconto/ares",
		},
		{
			title: "Tyche",
			description:
				"A credit card validator written in Go. (lunh algorithm)",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/go/go.png",
			linkText: "View Project",
			link: "https://github.com/torbenconto/tyche",
		},
		{
			title: "Bible CLI",
			description:
				"An ai-powered bible study tool for the command line, written in golang.",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/go/go.png",
			linkText: "View Project",
			link: "https://github.com/torbenconto/bible-cli",
		},
	],
};

export default INFO;
